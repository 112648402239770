import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import StartAnimation from './Components/StartAnimation';
import Home from './Pages/HomePage.js';
import AboutUs from './Pages/AboutUsPage'
import Catalog1 from './Pages/Catalog1Page';
import Catalog2 from './Pages/Catalog2Page';
import Catalog3 from './Pages/Catalog3Page';
import Catalog4 from './Pages/Catalog4Page';
import Login from './Pages/LoginPage';
import FailedLogin from './Pages/FailedLoginPage';
import Contact from './Pages/ContactPage';
import NavBar from './Components/NavBar';
import ErrorPage from './Pages/ErrorPage';
import Products from './Pages/ProductsPage';
import { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation();
  const [loginEnterFromFailure, setLoginEnterFromFailure] = useState(false); //Used to check wether we entered login from a the failedLoginPage or not
  return (
    <div className="App" >
      <NavBar className="navbar" />
      {/*Maybe a Browser router missing here?*/}
      <AnimatePresence mode="sync" initial={false} >
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<StartAnimation />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/fashion" element={<Catalog1 />} />
          <Route path="/industry" exact element={<Catalog2 />} />
          <Route path="/food" exact element={<Catalog3 />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/failedLogin" exact element={<FailedLogin />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login failureState = {loginEnterFromFailure} setFailureState={setLoginEnterFromFailure} />} />
          <Route path="/failedLogin" element={<FailedLogin  setFailureState={setLoginEnterFromFailure}/>} />
          <Route path="/fashion" element={<Catalog1 />} />
          <Route path="/industry" element={<Catalog2 />} />
          <Route path="/food" element={<Catalog3 />} />
          <Route path="/graphical" element={<Catalog4 />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" exact={true} element={<ErrorPage />} />
        </Routes>
      </AnimatePresence>
    </div >

  );
}

export default App;
