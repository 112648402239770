import '../Styles/Products.css'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrow from "../assets/image/products/arrow.svg"
import fashionBackground from "../assets/image/products/fashion-mobile.png"
import industryBackground from "../assets/image/products/industry-mobile.png"
import foodBackground from "../assets/image/products/food-mobile.png"
import graphicalBackground from "../assets/image/products/graphical-mobile.png"

function Products(){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = window.matchMedia('(max-width: 991px)').matches;
    return(
        <div>
            {!isMobile && ( //Web version
            <div>
                <div id="productsTitle">{t('productsUC')}</div>
                <div className='productsButtonLine'>
                    <div className='productsButtonLeft' id="productsFashionButton">
                        <div className='productsButtonBackgroundImage' id="productsFashionButtonImage" onClick={()=>{navigate("/fashion")}}>
                            <div className='productsButtonText'>
                                {t('fashionUC')}
                            </div>
                        </div>
                    </div>
                    <div className='productsButtonRight' id="productsIndustryButton">
                        <div className='productsButtonBackgroundImage' id="productsIndustryButtonImage" onClick={()=>{navigate("/industry")}}>
                            <div className='productsButtonText'>
                                {t('industryUC')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='productsButtonLine'>
                    <div className='productsButtonLeft' id="productsFoodButton">
                        <div className='productsButtonBackgroundImage' id="productsFoodButtonImage" onClick={()=>{navigate("/food")}}>
                            <div className='productsButtonText'>
                                {t('foodIndustryUC')}
                            </div>
                        </div>
                    </div>
                    <div className='productsButtonRight' id="productsGraphicalButton">
                        <div className='productsButtonBackgroundImage' id="productsGraphicalButtonImage" onClick={()=>{navigate("/graphical")}}>
                            <div className='productsButtonText'>
                                {t('layout-graphical')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {isMobile && ( //Mobile version
                <div>
                    <div id="productsMobileTitleDiv">{t('productsUC')}</div>
                    <div className='productsMobileCatalogDiv' id="productsFashionDiv">
                        <img src={fashionBackground} className="productsMobileCatalogBackgroundImage" id="productsBackgroundImageFashion"/>
                        <div className='productsMobileCatalogTitle'>{t("fashionUC")}</div>
                        <button className="productsMobileCatalogButton" onClick={()=>{navigate("/fashion");}}>
                            {t("homeAboutUsButton")}
                            <img src={arrow} className="productsArrowImage"/>
                        </button>
                    </div>
                    <div className='productsMobileCatalogDiv' id="productsIndustryDiv">
                        <img src={industryBackground} className="productsMobileCatalogBackgroundImage" id="productsBackgroundImageIndustry"/>
                        <div className='productsMobileCatalogTitle'>{t("industryUC")}</div>
                        <button className="productsMobileCatalogButton" onClick={()=>{navigate("/industry");}}>
                            {t("homeAboutUsButton")}
                            <img src={arrow} className="productsArrowImage"/>
                        </button>
                    </div>
                    <div className='productsMobileCatalogDiv' id="productsFoodDiv">
                        <img src={foodBackground} className="productsMobileCatalogBackgroundImage" id="productsBackgroundImageFood"/>
                        <div className='productsMobileCatalogTitle'>{t("foodIndustryUC")}</div>
                        <button className="productsMobileCatalogButton" onClick={()=>{navigate("/food");}}>
                            {t("homeAboutUsButton")}
                            <img src={arrow} className="productsArrowImage"/>
                        </button>
                    </div>
                    <div className='productsMobileCatalogDiv' id="productsGraphicalDiv">
                        <img src={graphicalBackground} className="productsMobileCatalogBackgroundImage" id="productsBackgroundImageGraphical"/>
                        <div className='productsMobileCatalogTitle'>{t("layout-graphical")}</div>
                        <button className="productsMobileCatalogButton" onClick={()=>{navigate("/graphical");}}>
                            {t("homeAboutUsButton")}
                            <img src={arrow} className="productsArrowImage"/>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Products;