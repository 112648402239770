import React from "react";
import { useTranslation } from 'react-i18next';
import box1 from "../assets/image/catalog/fashion/box-fashion.png";
import box1mobile from "../assets/image/catalog/fashion/box-fashion-mobile.png";
import beltImage from "../assets/image/catalog/fashion/cinto_1.png";
import tagImage from "../assets/image/catalog/fashion/tag_1_1.png";
import caixaCalcado from "../assets/image/catalog/fashion/caixa-calcado.png";
import caixaCalcadoMobile from "../assets/image/catalog/fashion/caixa-calcado-mobile.png";
import meias from "../assets/image/catalog/fashion/meias.png";
import pillows from "../assets/image/catalog/fashion/pillows.png";
import toalhas from "../assets/image/catalog/fashion/toalhas.png";
import downArrow from "../assets/image/catalog/down-arrow.png";


function Catalog1() {
    const { t } = useTranslation();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="Container d-none d-lg-block">
                <div className="layout" >
                    <div className="start-fashion">
                        <img src={box1} alt="layout" id="BlueLayout" />
                    </div>
                    <p id="text-layout">{t('fashionUC')}</p>
                </div>

                <div className="fashion-products d-flex">
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={caixaCalcado} alt="first" id="fashion-img1" />
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('f-product1-title')}</p>
                        <p id="description-catalog">{t('product1-desc')}</p>
                    </div>
                </div>

                <div className="fashion-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('f-product2-title')}</p>
                        <p id="description-catalog">{t('product2-desc')}</p>
                    </div>
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={beltImage} alt="first" id="fashion-img2" />
                    </div>
                </div>

                <div className="fashion-products d-flex">
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={tagImage} alt="first" id="fashion-img3" />
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="description-catalog">{t('product3-desc')}</p>
                    </div>
                </div>

                <div className="fashion-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="description-catalog">{t('product4-desc')}</p>
                    </div>
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={meias} alt="first" id="fashion-img4" />
                    </div>
                </div>

                <div className="fashion-products d-flex">
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={pillows} alt="first" id="fashion-img5" />
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('f-product5-title')}</p>
                        <p id="description-catalog">{t('product5-desc')}</p>
                    </div>
                </div>

                <div className="fashion-products d-flex fashion-catalog-bottom">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="description-catalog">{t('product6-desc')}</p>
                    </div>
                    <div className="fashion-img-div col-lg-6 col-md-6">
                        <img src={toalhas} alt="first" id="fashion-img6" />
                    </div>
                </div>
            </div>
            {/*Mobile version*/}
            <div className='Container d-lg-none d-block'>
                <div className='Container-mobile d-lg-none d-block'>
                <div className="layout-mobile-catalog">
                    <div className="start-fashion-mobile">
                        <img src={box1mobile} alt="layout" id="BlueLayout-mobile" />
                    </div>
                    <p id="text-layout-mobile-fashion">{t('fashionUC')}</p>
                    <img src={downArrow} className="catalogDownArrow"/>
                </div>

                <div className="fashion-products-mobile d-flex">
                    <div id="catalog1MobileTitle1" className="catalogMobileTitlesLeft">{t("f-product1-title")}</div>
                    <div id="catalog1MobileText1" className="catalogMobileTextsLeft">{t("product1-desc")}</div>
                    <img src={caixaCalcadoMobile} alt="first" id="label-img" />
                </div>

                <div className="fashion-products-mobile d-flex">
                    <div id="catalog1MobileTitle2" className="catalogMobileTitlesRight">{t("f-product2-title")}</div>
                    <div id="catalog1MobileText2" className="catalogMobileTextsRight">{t("product2-desc")}</div>
                    <img src={beltImage} alt="first" id="blueBoxMobile-img"/>
                </div>

                <div className="fashion-products-mobile d-flex" id="belt" >
                    <div id="catalog1MobileText3" className="catalogMobileTextsRight">{t("product3-desc")}</div>
                    <img src={tagImage} alt="first" id="belt-img" />
                </div>

                <div className="fashion-products-mobile d-flex">
                    <div id="catalog1MobileText4" className="catalogMobileTextsLeft">{t("product4-desc")}</div>
                    <img src={meias} alt="first" id="meias-img" />
                </div>

                <div className="fashion-products-mobile d-flex">
                    <div id="catalog1MobileTitle5" className="catalogMobileTitlesRight">{t("f-product5-title")}</div>
                    <div id="catalog1MobileText5" className="catalogMobileTextsRight">{t("product5-desc")}</div>
                    <img src={pillows} alt="first" id="etiqueta-img" />
                </div>

                <div className="fashion-products-mobile d-flex">
                    <div id="catalog1MobileText6" className="catalogMobileTextsLeft">{t("product6-desc")}</div>
                    <img src={toalhas} alt="first" id="toalhas-img" />
                </div>
            </div >
            </div>
        </div >
    );
}

export default Catalog1;