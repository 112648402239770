import React from "react";
import "../../Styles/Animations/Circles.css";

function CirclesOrange() {
    return (
        <div className="circles-orange">
            <div className="circle-3" style={{ backgroundColor: 'rgba(209, 138, 92, 0.25)' }}></div>
            <div className="circle-2" style={{ backgroundColor: 'rgba(209, 138, 92, 0.25)' }}></div>
            <div className="circle-1" style={{ backgroundColor: 'rgba(209, 138, 92, 1)' }}></div>
        </div >
    )
}

export default CirclesOrange;
