import React from 'react';
import "../Styles/Footer.css";
import { useTranslation } from 'react-i18next';
import logo from "../Icons/dldLogo_footer.png"
import facebook from "../Icons/facebook.svg"
import instagram from "../Icons/instagram.svg"
import linkdIn from "../Icons/linkdin.svg"
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    const isMobile = window.matchMedia('(max-width: 991px)').matches;
    return (
        <footer className='main-footer'>
            <div className="container-footer">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <ul className='list-unstyled1'>
                            <p>R. 1º de Maio 844 <br></br> Pencelo, Guimarães</p>
                            <p id="numberFooterDiv">+351 253 559 240</p>
                            <p id={isMobile?"nationalCallFooterPMobile":"nationalCallFooterP"}>{t('nationalCall')}</p>
                            <p>info@dldcoutinhos.com</p>
                            <p>comercial@dldcoutinhos.com</p>
                        </ul>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <ul className="list-unstyled2">
                            <div className='logo-footer d-none d-lg-block'>
                                <img src={logo} alt="logo" id="logo-footer" />
                            </div>
                            <div className='icons d-none d-lg-block'>
                                <a href="https://www.instagram.com/dldgrafica/"><img src={instagram} alt="instagram" id="icon-instagram" /></a>
                                <a href="https://www.linkedin.com/company/dldcoutinhos/"><img src={linkdIn} alt="linkdin" id="icon-linkdin" /></a>
                            </div>
                            <div className='icons d-block d-lg-none'>
                                <a className="px-3" href="https://www.instagram.com/dldgrafica"><img src={instagram} alt="instagram" id="icon-instagram-mobile" /></a>
                                <a className="px-3" href="https://www.linkedin.com/company/dldcoutinhos/"><img src={linkdIn} alt="linkdin" id="icon-linkdin-mobile" /></a>
                            </div>
                        </ul>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block">
                        <ul className='list-unstyled3'>
                            <li><Link to="/home">{t('home')}</Link></li>
                            <li><Link to="/aboutUs">{t('aboutUs')}</Link></li>
                            <li><Link to="/fashion">{t('fashion')}</Link></li>
                            <li><Link to="/industry">{t('industry')}</Link></li>
                            <li><Link to="/food">{t('food')}</Link></li>
                            <li><Link to="/graphical">{t('graphical')}</Link></li>
                            <li><Link to="/contact">{t('contacts')}</Link></li>
                            <li><Link to="/login">{t('privateArea')}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-xs-12 col-sm-12 col-md-12 mt-1 mt-sm-1 text-center text-white" id="final">
                        &copy; {new Date().getFullYear()} DLD Coutinho&apos;s - All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;