import React from "react";
import { useTranslation } from 'react-i18next';
import boxIndustry from "../assets/image/catalog/industry/box-industry.png";
import boxIndustryMobile from "../assets/image/catalog/industry/box-industry-mobile.png";
import industry1 from "../assets/image/catalog/industry/cosmetica.png";
import ceramicos from "../assets/image/catalog/industry/ceramicos 1.png"
import pliers from "../assets/image/catalog/industry/pliers 1.png";
import pliersMobile from "../assets/image/catalog/industry/pliers-mobile.png";
import box from "../assets/image/catalog/industry/box-web.png";
import boxMobile from "../assets/image/catalog/industry/box-web-mobile.png";
import sabao from "../assets/image/catalog/industry/sabao 1.png";
import sabaoMobile from "../assets/image/catalog/industry/sabao-mobile.png";
import meds from "../assets/image/catalog/industry/meds 1.png";
import medsMobile from "../assets/image/catalog/industry/medsMobile.png";
import downArrow from "../assets/image/catalog/down-arrow.png";


function Catalog2() {
    const { t } = useTranslation();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="Container d-none d-lg-block">
                <div className="layout" >
                    <div className="start-industry">
                        <img src={boxIndustry} alt="layout" id="OrangeLayout"/>
                    </div> 
                    <p id="text-layout">{t('layout-industry')}</p>
                </div>

                <div className="industry-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('i-product1-title')}</p>
                        <p id="description-catalog">{t('product1-desc')}</p>
                    </div>
                    <div className= "industry-img-div col-lg-6 col-md6">
                        <img src={ceramicos} alt="first" id="industry-img1"/>
                    </div>
                </div>

                <div className="industry-products d-flex" id="box2">
                    <div className= "industry-img-div col-lg-6 col-md6" id="img2">
                        <img src={pliers} className="metal" alt="first" id="industry-img2" />
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('i-product2-title')}</p>
                        <p id="description-catalog">{t('product2-desc')}</p>
                    </div>
                </div>
                
                <div className="industry-products d-flex" id="box2">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <div className="espaco">
                        <p id="description-catalog">{t('product3-desc')}</p>
                        </div>
                    </div>
                    <div className= "industry-img-div col-lg-6 col-md6" id="img3">
                        <img src={box} alt="first" id="industry-img3"/>
                    </div>
                </div>

                <div className="industry-products d-flex">
                    <div className= "industry-img-div col-lg-6 col-md6">
                        <img src={industry1} alt="first" id="industry-img4"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('i-product4-title')}</p>
                        <p id="description-catalog">{t('product4-desc')}</p>
                    </div>
                </div>

                <div className="industry-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <div className="espaco">
                            <p id="description-catalog">{t('product5-desc')}</p>
                        </div>
                    </div>
                    <div className= "fashion-img-div col-lg-6 col-md6" id="img3">
                        <img src={sabao} alt="first" id="industry-img5"/>
                    </div>
                </div>

                <div className="industry-products d-flex" id="industry-bottom-div">
                    <div className= "fashion-img-div col-lg-6 col-md6">
                        <img src={meds} alt="first" id="industry-img6"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('i-product6-title')}</p>
                        <p id="description-catalog">{t('product6-desc')}</p>
                    </div>
                </div>
            </div>

             {/*Mobile version*/}
             <div className='Container d-lg-none d-block'>
                <div className='Container-mobile d-lg-none d-block'>
                    <div className="layout-mobile-catalog" >
                        <div className="start-industry-mobile">
                            <img src={boxIndustryMobile} alt="layout" id="OrangeLayout-mobile"/>
                        </div> 
                        <p id="text-layout-mobile">{t('layout-industry')}</p>
                        <img src={downArrow} className="catalogDownArrow"/>
                    </div>

                    <div className="fashion-products-mobile d-flex" >
                    <div id="catalog2MobileTitle1" className="catalogMobileTitlesLeft">{t("i-product1-title")}</div>
                    <div id="catalog2MobileText1" className="catalogMobileTextsLeft">{t("product1-desc")}</div>
                        <img src={ceramicos} alt="first" id="industry1-img-mobile"/>
                    </div>

                    <div className="fashion-products-mobile d-flex">
                    <div id="catalog2MobileTitle2" className="catalogMobileTitlesRight">{t("i-product2-title")}</div>
                    <div id="catalog2MobileText2" className="catalogMobileTextsRight">{t("product2-desc")}</div>
                        <img src={pliersMobile} alt="first" id="industry2-img-mobile"/>
                    </div>

                    <div className="fashion-products-mobile d-flex" id="belt" >
                    <div id="catalog2MobileText3" className="catalogMobileTextsLeft">{t("product3-desc")}</div>
                        <img src={boxMobile} alt="first" id="industry3-img-mobile"/>
                    </div>

                    <div className="fashion-products-mobile d-flex">
                    <div id="catalog2MobileTitle4" className="catalogMobileTitlesRight">{t("i-product4-title")}</div>
                    <div id="catalog2MobileText4" className="catalogMobileTextsRight">{t("product4-desc")}</div>
                        <img src={industry1} alt="first" id="industry4-img-mobile"/>
                    </div>

                    <div className="fashion-products-mobile d-flex">
                    <div id="catalog2MobileText5" className="catalogMobileTextsLeft">{t("product5-desc")}</div>
                        <img src={sabaoMobile} alt="first" id="industry5-img-mobile"/>
                    </div>

                    <div className="fashion-products-mobile d-flex">
                    <div id="catalog2MobileTitle6" className="catalogMobileTitlesRight">{t("i-product6-title")}</div>
                    <div id="catalog2MobileText6" className="catalogMobileTextsRight">{t("product6-desc")}</div>
                        <img src={medsMobile} alt="first" id="industry6-img-mobile"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Catalog2;