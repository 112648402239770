import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import '../Styles/Contact.css';
import MapBackground from "../assets/image/contact/mapBackground.webp";
import Arrow from "../assets/image/contact/arrow.svg";


const Contact = () => {
    const { t } = useTranslation();

    //this is for the button animation
    const [animate, setAnimate] = useState(false);
    const handleClick = () => {
        setAnimate(true);
        setTimeout(() => {
            setAnimate(false);
        }, 3000);
    };

    
    return (
        <div>
            {/** Desktop */}
            <div className="contact d-none d-lg-block">
                <img src={MapBackground} className="map-background" />
                <p className="contact-title">{t("contactUC")}</p>
                <div className="forms-container">
                    <div className="form-group">
                        <div className="form-line">
                            <label className="form-label">
                                <input type="text" placeholder={t("fullName")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <input type="text" placeholder={t("company")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <input type="email" required placeholder={t("email")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <input type="text" placeholder={t("phone")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                            <label className="form-label">
                                <input type="text" placeholder={t("nif")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <input type="text" placeholder={t("address")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <input type="text" placeholder={t("city")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                            <label className="form-label">
                                <input type="text" placeholder={t("zip")} className="input-box" />
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-line">
                            <label className="form-label">
                                <textarea type="text" placeholder={t("message")} className="input-box" onInput='this.style.height = "";this.style.height = this.scrollHeight + "px"'/>
                                <div className="form-underline"></div>
                            </label>
                        </div>
                        <div className="form-buttons">
                            <div className="form-button">
                                <input id="form-checkbox" type="checkbox" className="form-checkbox" name={t("subscribe")} />
                                <label htmlFor="form-checkbox" className="form-textarea">{t("subscribe")}</label>

                            </div>
                            <div className="form-button">
                                <label>
                                    <button type="submit" className={'form-submit ' + (animate ? 'animate' : '')} onClick={handleClick}>
                                        <div className="submit-text">
                                            {t('sendButton')}
                                        </div>
                                        <div className="submit-dots">
                                            <div className="submit-dot"></div>
                                            <div className="submit-dot"></div>
                                            <div className="submit-dot"></div>
                                        </div>
                                        <div className="submit-check"></div>
                                    </button>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maps-button">
                    <a type="button" href="https://goo.gl/maps/jRNZaMV5ngjdcNcS7" target="_blank" rel="noreferrer noopener" className="maps-button-content">
                        <div className="maps-button-background"></div>
                        <p className="maps-text">{t("openMaps")}</p>
                        <img src={Arrow} className="maps-arrow" />
                    </a>
                </div>
                <div className="contact-circles">
                    <div className="contact-circles-blue">
                        <div className="contact-circle-3" style={{ backgroundColor: 'rgba(173, 199, 210, 0.25)' }}></div>
                        <div className="contact-circle-2" style={{ backgroundColor: 'rgba(173, 199, 210, 0.25)' }}></div>
                        <div className="contact-circle-1" style={{ backgroundColor: 'rgba(173, 199, 210, 0.5)' }}></div>
                    </div >
                </div>
                <div className="contact-address-hover">
                    R. 1º de Maio 844 Pencelo, Guimarães
                </div>
            </div >
            {/** Mobile */}
            <div className="contact-mobile d-lg-none d-block">
                <div className="map-background-mobile" />
                <p className="contact-title-mobile">{t("contactUC")}</p>
                <div className="forms-container-mobile">
                    <div className="form-group-mobile">
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("fullName")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("company")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("email")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("phone")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("nif")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("address")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("city")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("zip")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-line-mobile">
                            <label className="form-label-mobile">
                                <input type="text" placeholder={t("message")} className="input-box-mobile" />
                                <div className="form-underline-mobile"></div>
                            </label>
                        </div>
                        <div className="form-buttons-mobile">
                            <div className="form-button-mobile">
                                <input id="form-checkbox-mobile" type="checkbox" className="form-checkbox-mobile" name={t("subscribe")} />
                                <label htmlFor="form-checkbox-mobile" className="form-textarea-mobile">{t("subscribe")}</label>
                            </div>
                            <div className="form-button-mobile">
                                <label>
                                    <button className={'form-submit-mobile ' + (animate ? 'animate' : '')} onClick={handleClick}>
                                        <div className="submit-text-mobile">
                                            {t('sendButton')}
                                        </div>
                                        <div className="submit-dots-mobile">
                                            <div className="submit-dot-mobile"></div>
                                            <div className="submit-dot-mobile"></div>
                                            <div className="submit-dot-mobile"></div>
                                        </div>
                                        <div className="submit-check-mobile"></div>
                                    </button>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Contact;