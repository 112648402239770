import ReactPlayer from 'react-player';
import React, { useState } from 'react';
import video from '../../assets/video/fundo.mp4';
import placeholder from '../../assets/video/placeholder.webp';
import playIcon from '../../assets/video/playIcon.png';


function VideoHome() {
  const [showVideo, setShowVideo] = useState(false)

  const isMobile = window.matchMedia('(max-width: 991px)').matches;
  const playVideo = () => {
    setShowVideo(true);
  }

  return (
    <div>
      {!showVideo && 
        <div onClick={playVideo} className= 'video-container'> 
          <img src={placeholder} className='placeholder-img' alt='Placeholder' width='100%' height='100%'/>
          <img src={playIcon} className='play-icon' alt='Play' />
        </div>
      }
      {showVideo && !isMobile && <div id="homeWebVideoDiv"><ReactPlayer url={video} playing controls onEnded={() => setShowVideo(false)}  width='100vw' height="56.2vw" /></div>}
      {showVideo && isMobile && <ReactPlayer url={video} playing controls onEnded={() => setShowVideo(false)}  width='220vw' height="120vw" />}
    </div>
  )
}

export default VideoHome;