import React from "react";
import { useTranslation } from 'react-i18next';
import bottle from "../assets/image/catalog/food/bottle_green.png";
import bottleMobile from "../assets/image/catalog/food/bottle-mobile.png";
import food1 from "../assets/image/catalog/food/garrafa.png";
import food2 from "../assets/image/catalog/food/garrafa_pequena.png";
import food3 from "../assets/image/catalog/food/caixaComida.png";
import food4 from "../assets/image/catalog/food/food4.png";
import food4Mobile from "../assets/image/catalog/food/food4-mobile.png";
import food5 from "../assets/image/catalog/food/food5.png";
import food5Mobile from "../assets/image/catalog/food/food5-mobile.png";
import food6 from "../assets/image/catalog/food/macarrons-1.png"
import food6Mobile from "../assets/image/catalog/food/food6-mobile.png"
import bootleBox from "../assets/image/catalog/food/bottleBox_mobile.png";
import downArrow from "../assets/image/catalog/down-arrow.png";

function Catalog3() {
    const { t } = useTranslation();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="Container d-none d-lg-block">
                <div className="layout" >
                    <div className="start-food">
                        <img src={bottle} alt="layout" id="GreenLayout"/>
                    </div> 
                    <p id="text-layout">{t('layout-food')}</p>
                </div>

                <div className="food-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('a-product1-title')}</p>
                        <p id="description-catalog">{t('product1-desc')}</p>
                    </div>
                    <div className= "food-img-div col-lg-6 col-md-6" id="right">
                        <img src={food1} alt="first" id="food1-img"/>
                    </div>
                </div>

                <div className="food-products d-flex" id="section2">
                    <div className= "food-img-div col-lg-6 col-md-6">
                        <img src={food2} alt="first" id="food2-img" />
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="description-catalog">{t('product2-desc')}</p>
                    </div>
                </div>
                
                <div className="food-products d-flex" id="section3">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('a-product3-title')}</p>
                        <p id="description-catalog">{t('product3-desc')}</p>
                    </div>
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={food3} alt="first" id="food3-img"/>
                    </div>
                </div>

                <div className="food-products d-flex">
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={food4} alt="first" id="food4-img"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('a-product4-title')}</p>
                        <p id="description-catalog">{t('product4-desc')}</p>
                    </div>
                </div>

                <div className="food-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('a-product5-title')}</p>
                        <p id="description-catalog">{t('product5-desc')}</p>
                    </div>
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={food5} alt="first" id="food5-img"/>
                    </div>
                </div>

                <div className="food-products d-flex" id="lastOne-catalog3">
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={food6} alt="first" id="food6-img"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('a-product6-title')}</p>
                        <p id="description-catalog">{t('product6-desc')}</p>
                    </div>
                </div>
            </div>

            {/*Mobile version*/}
            <div className='Container d-lg-none d-block'>
                <div className="layout-mobile-catalog">
                    <div className="start-food-mobile">
                        <img src={bottleMobile} alt="layout" id="GreenLayout-mobile"/>
                    </div> 
                    <p id="text-layout-mobile-food">{t('layout-food')}</p>
                    <img src={downArrow} className="catalogDownArrow"/>
                </div>

                <div className="food-products d-flex">
                    <div id="catalog3MobileTitle1" className="catalogMobileTitlesLeft">{t("a-product1-title")}</div>
                    <div id="catalog3MobileText1" className="catalogMobileTextsLeft">{t("product1-desc")}</div>
                    <img src={bootleBox} alt="first" id="food1-img-mobile"/>
                </div>

                <div className="food-products d-flex" >
                    <div id="catalog3MobileText2" className="catalogMobileTextsRight">{t("product2-desc")}</div>
                    <img src={food2} alt="first" id="food2-img-mobile"/>                  
                </div>

                <div className="food-products d-flex">
                    <div id="catalog3MobileTitle3" className="catalogMobileTitlesLeft">{t("a-product3-title")}</div>
                    <div id="catalog3MobileText3" className="catalogMobileTextsLeft">{t("product3-desc")}</div>
                    <img src={food3} alt="first" id="food3-img-mobile"/>
                </div>


                <div className="food-products d-flex">
                    <div id="catalog3MobileTitle4" className="catalogMobileTitlesRight">{t("a-product4-title")}</div>
                    <div id="catalog3MobileText4" className="catalogMobileTextsRight">{t("product4-desc")}</div>
                    <img src={food4Mobile} alt="first" id="food4-img-mobile"/>
                </div>

                <div className="food-products d-flex">
                    <div id="catalog3MobileTitle5" className="catalogMobileTitlesLeft">{t("a-product5-title")}</div>
                    <div id="catalog3MobileText5" className="catalogMobileTextsLeft">{t("product5-desc")}</div>
                    <img src={food5Mobile} alt="first" id="food5-img-mobile"/>
                </div>

                <div className="food-products d-flex">
                    <div id="catalog3MobileTitle6" className="catalogMobileTitlesRight">{t("a-product6-title")}</div>
                    <div id="catalog3MobileText6" className="catalogMobileTextsRight">{t("product6-desc")}</div>
                    <img src={food6Mobile} alt="first" id="food6-img-mobile"/>
                </div>
            </div>
        </div>
    );
}

export default Catalog3;