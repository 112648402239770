import React from "react";
import "../Styles/NavBar.css";
import LanguageDropDown from "./LanguageDropDown";
import { useTranslation } from "react-i18next"
import { useState } from "react";
import logo from "../assets/icons/logoDLD.svg";
import logoMobile from "../assets/icons/dldLogoMobile.svg";

import { Link } from "react-router-dom";


function NavBar() {
    const isMobile = window.matchMedia('(max-width: 991px)').matches;
    const { t } = useTranslation();

    const [state, setState] = useState(false);

    const animateButton = () => {
        setState(state => !state);
    }

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => {
        //disable scroll when navbar is open
        if (isMobile && isNavCollapsed) {
            document.body.classList.add("stop-scrolling");
        } else {
            document.body.classList.remove("stop-scrolling");
        }

        setIsNavCollapsed(!isNavCollapsed);
        animateButton();
    }

    const collapseNavbar = () => {
        handleNavCollapse();
    }

    return (

        <div className="col-12" >
            <nav className="navbar navbar-expand-lg">
                <Link id="logodld" className="d-none d-lg-block" to="/home">
                    <img src={logo} alt="logo" id="logo-web" />
                </Link>
                <Link id="logodld-mobile" className="d-lg-none d-block" to="/home">
                    <img src={logoMobile} alt="logo" id="logo-mobile" />
                </Link>
                <div id="navbar" className="container">
                    <div className="navbar-collapse ">
                        <div className={"navbar-background " + (isNavCollapsed ? 'collapsed' : 'open')}></div>
                        <ul id="items" className={"menu-item-div " + (isNavCollapsed ? 'collapsed' : 'open')}>
                            <Link id="home" className={'menu-item ' + (window.location.pathname === '/home' ? 'active-menu-item' : '')} to="/home" onClick={collapseNavbar}>
                                {t('homeUC')}
                            </Link>
                            <Link id="aboutUs" className={'menu-item ' + (window.location.pathname === '/aboutUs' ? 'active-menu-item' : '')} to="/aboutUs" onClick={collapseNavbar}>
                                {t('aboutUsUC')}
                            </Link>
                            <Link id="products" className={'menu-item ' + (window.location.pathname === '/products' ? 'active-menu-item' : '')} to="/products" onClick={collapseNavbar}>
                                {t('productsUC')}
                            </Link>
                            <Link id="contact" className={'menu-item ' + (window.location.pathname === '/contact' ? 'active-menu-item' : '')} to="/contact" onClick={collapseNavbar}>
                                {t('contactUC')}
                            </Link>
                            <Link id="login" className={'menu-item ' + (window.location.pathname === '/login' ? 'active-menu-item' : '')} to="/login" onClick={collapseNavbar}>
                                {t('loginUC')}
                            </Link>
                            <a id="language" className='menu-item'>
                                <LanguageDropDown />
                            </a>
                        </ul>
                    </div>
                    <div className="navbar-header sticky">
                        <button className={"navbar-toggler shadow-none " + (state ? "" : "animate")} type="button" onClick={collapseNavbar} >
                            <div className="icon-bar"></div>
                            <div className="icon-bar"></div>
                            <div className="icon-bar"></div>
                        </button>
                    </div>
                </div>
            </nav >
        </div >

    );
}

export default NavBar;
