import '../Styles/AboutUs.css';
import Footer from '../Components/Footer';
import Home from '../Components/Home';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function checkStartAnimationPrecedence() {
  if ("startAnimationPrecedence" in window) {
    if (window.startAnimationPrecedence === true) {
      window.startAnimationPrecedence = false;
      return true;
    }
  }
  return false;
}


const HomePage = () => {
  {/*This is used to deactivate the web animation between pages*/ }
  const isMobile = window.matchMedia('(max-width: 991px)').matches;
  //Code to make page scroll up like a power point 
  const [pageStarting, setPageStarting] = useState(true);
  useEffect(()=>{
    setTimeout(()=>{if(pageStarting){setPageStarting(false);window.scrollTo({top:0,left:0,behavior:"auto"});console.log("SUCCESS")}},550);
  })
  return (
    <motion.div id="homePage"

      initial={!isMobile && !checkStartAnimationPrecedence() && ({ top: `100vh` })}
      animate={!isMobile && !checkStartAnimationPrecedence() && ({ top: "0vh" })}
      exit={!isMobile && !checkStartAnimationPrecedence() && ({ filter: 'brightness(50%)', top: `-20vh` })}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className={pageStarting?"page-transition-fixed":"page-transition-absolute"}
    >
      <Home />
      <Footer />
    </motion.div>


  );
}

export default HomePage;