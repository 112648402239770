import '../Styles/Login.css';
import Footer from '../Components/Footer';
import Login from '../Components/Login';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';




const LoginPage = (props) => {
  {/*This is used to deactivate the web animation between pages*/ }
  const isMobile = window.matchMedia('(max-width: 991px)').matches;
  //Code to make page scroll up like a power point 
  const [pageStarting, setPageStarting] = useState(true);
  useEffect(()=>{
    setTimeout(()=>{if(pageStarting){setPageStarting(false);window.scroll({top:0,left:0,behavior:"auto"});console.log("SUCCESS")}},550);
  })
  console.log(props.failureState)
  if (!props.failureState){
    return (
      <motion.div 
        initial={!isMobile && ({ top: `100vh` })}
        animate={!isMobile && ({ top: "0vh" })}
        exit={!isMobile && ({ filter: 'brightness(50%)', top: `-20vh` })}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className={(pageStarting?"page-transition-fixed":"page-transition-absolute") + " loginPage"}
      >
        <Login />
        <Footer />
      </motion.div>
    );
  }else{
    props.setFailureState(false);
    return (
      <motion.div 
        animate={!isMobile && ({ top: "0vh" })}
        exit={!isMobile && ({ filter: 'brightness(50%)', top: `-20vh` })}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className={(pageStarting?"page-transition-fixed":"page-transition-absolute") + " loginPage"}
      >
        <Login />
        <Footer />
      </motion.div>
    );
  }
}

LoginPage.propTypes = {
  failureState: PropTypes.func.isRequired,
  setFailureState: PropTypes.func.isRequired
}

export default LoginPage;