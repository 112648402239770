import '../Styles/Contact.css';
import Footer from '../Components/Footer';
import Contact from '../Components/Contact';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const ContactPage = () => {

    {/*This is used to deactivate the web animation between pages*/ }
    const isMobile = window.matchMedia('(max-width: 991px)').matches;
    //Code to make page scroll up like a power point 
    const [pageStarting, setPageStarting] = useState(true);
    useEffect(()=>{
        setTimeout(()=>{if(pageStarting){setPageStarting(false);window.scrollTo({top:0,left:0,behavior:"auto"});console.log("SUCCESS")}},550);
    })

    return (
        <motion.div id="contactPage"
            initial={!isMobile && ({ top: `100vh` })}
            animate={!isMobile && ({ top: "0vh" })}
            exit={!isMobile && ({ filter: 'brightness(50%)', top: `-20vh` })}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={pageStarting?"page-transition-fixed":"page-transition-absolute"}
        >

            <Contact />
            <Footer />
        </motion.div>
    );
}

export default ContactPage;