import React from "react";
import "../../Styles/Animations/PalletteAnimation.css";
import { useState, useEffect } from "react";

function PalletteAnimation() {
  const isMobile = window.matchMedia('(max-width: 991px)').matches;



  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const element = document.querySelector(".pallete-mobile");
      if (element == null){
        return;
      }
      const elementTop = element.getBoundingClientRect().top;
      const elementBottom = element.getBoundingClientRect().bottom;
      const screenHeight = window.innerHeight;

      if(isMobile){
        // Check if the element is in the viewport
        if(elementBottom != null && elementTop != null){
          if (elementBottom > 0 && elementTop < screenHeight*0.5) {
            setScroll(true);
          } else {
            setScroll(false);
          }
        }
      }
    });


  }, [scroll, window.scrollY]);

  return (
    <div className={scroll ? "pallette-animation-animate" : "pallette-animation"} id="pallette-animation-mobile">
      <div className="top-card"  >
        <div className="background" />
        <div className="foreground" />
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-3" />
        <div className="color-4" />
        <div className="old-logo" />
        <div className="new-logo" />
      </div>
      <div className="middle-card" >
        <div className="background" />
        <div className="foreground" />
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-3" />
        <div className="color-4" />
        <div className="old-logo" />
        <div className="new-logo" />
      </div>
      <div className="bottom-card" >
        <div className="background" />
        <div className="foreground" />
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-3" />
        <div className="color-4" />
        <div className="old-logo" />
        <div className="new-logo" />
      </div>
    </div>
  )
  
}



export default PalletteAnimation;
