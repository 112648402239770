import React, {useState} from "react";
import { AnimatePresence, motion } from 'framer-motion';
import "../../Styles/Animations/homeDLDDescription.css"
import { useTranslation } from "react-i18next";


const updateTime = 2000; //In milliseconds

let isMobile = window.matchMedia('(max-width: 991px)').matches;

window.addEventListener("resize",()=>{
    isMobile = window.matchMedia('(max-width: 991px)').matches;
})

let updateFunction = (showWord1,setShowWord1,showWord2,setShowWord2,showWord3,setShowWord3,showWord4,setShowWord4)=>{
    if (showWord1 == true){
        setShowWord1(false);
        setShowWord2(true);
    }else if (showWord2 == true){
        setShowWord2(false);
        setShowWord3(true);
    }else if (showWord3 == true){
        setShowWord3(false);
        setShowWord4(true);
    }else if (showWord4 == true){
        setShowWord4(false);
        setShowWord1(true);
    }
    setTimeout(updateFunction,updateTime);
}

function HomeDLDDescription(){
    const {t} = useTranslation();
    const [showWord1,setShowWord1] = useState(true);
    const [showWord2,setShowWord2] = useState(false);
    const [showWord3,setShowWord3] = useState(false);
    const [showWord4,setShowWord4] = useState(false);
    setTimeout(updateFunction,updateTime,showWord1,setShowWord1,showWord2,setShowWord2,showWord3,setShowWord3,showWord4,setShowWord4)

    return (
        <div style={{position:"relative"}}>
            <div id="homeDLDDescriptionDiv">
                <AnimatePresence>
                    {showWord1 &&(
                    <motion.div className={isMobile?"homeDLDDescriptionWordMobile":"homeDLDDescriptionWord"} id="homeDLDDescriptionWord1" key={"homeDLDDescriptionWord1"}
                    initial={{opacity:0,y:"5vw"}} animate={{opacity:1,y:0}} transition={{ease:"easeInOut"}} exit={{opacity:0,y:"-5vw"}}>
                        {t('homeDLDDescriptionWord1')}
                    </motion.div>
                    )}
                    {showWord2 && (
                    <motion.div className={isMobile?"homeDLDDescriptionWordMobile":"homeDLDDescriptionWord"} id="homeDLDDescriptionWord2" key={"homeDLDDescriptionWord2"}
                    initial={{opacity:0,y:"5vw"}} animate={{opacity:1,y:0}}  transition={{ease:"easeInOut"}} exit={{opacity:0,y:"-5vw"}}>
                        {t('homeDLDDescriptionWord2')}
                    </motion.div>
                    )}
                    {showWord3 && (
                    <motion.div className={isMobile?"homeDLDDescriptionWordMobile":"homeDLDDescriptionWord"} id="homeDLDDescriptionWord3" key={"homeDLDDescriptionWord3"}
                    initial={{opacity:0,y:"5vw"}} animate={{opacity:1,y:0}} transition={{ease:"easeInOut"}} exit={{opacity:0,y:"-5vw"}}>
                        {t('homeDLDDescriptionWord3')}
                    </motion.div>
                    )}
                    {showWord4 && (
                    <motion.div className={isMobile?"homeDLDDescriptionWordMobile":"homeDLDDescriptionWord"} id="homeDLDDescriptionWord4" key={"homeDLDDescriptionWord4"}
                    initial={{opacity:0,y:"5vw"}} animate={{opacity:1,y:0}} transition={{ease:"easeInOut"}} exit={{opacity:0,y:"-5vw"}}>
                        {t('homeDLDDescriptionWord4')}
                    </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default HomeDLDDescription;