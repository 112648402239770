import React from "react";
import { useTranslation } from 'react-i18next';
import "../Styles/Home.css";
import HomeDLDDescription from "./Animations/homeDLDDescription";
import Video from './Animations/videoHome';
import HomeProductButtons from "./HomeProductButtons.js";
import homePalette from "../assets/image/home/homepage-pallete.png";
// import startHome from "../assets/image/home/homepage-begining-background-image-mobile.png";
import ReactPlayer from "react-player";
import capa_4 from "../assets/video/fundo.mp4";
import bottle from "../assets/image/home/bottle.png";
import arrow from "../assets/image/products/arrow.svg";
import productsFashion from "../assets/image/home/products-fashion.png";
import productsIndustry from "../assets/image/home/products-industry.png";
import productsFood from "../assets/image/home/products-food.png";
import productsGraphical from "../assets/image/home/products-graphical.png";

import { useNavigate } from "react-router-dom";

function Home() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            {/*Desktop version*/}
            <div className='Container d-none d-lg-block'>
                <div className="player-wrapper">
                <ReactPlayer url={capa_4} playing={true} loop={true} playsinline controls={false} volume={0} muted width="100%" height="100%"/>

                </div>
                <div id="homeTitleDivOverlay">
                    <div id="dld-text">
                        DLD
                    </div>
                    <div id="industria-grafica">
                        INDÚSTRIA GR�?FICA
                    </div>
                </div>
                <div id="homeDescriptionDiv">
                    <div id="homeDescriptionLeftDiv">
                        <div id="homeDescriptionSinceDiv">
                            {t("homeDescriptionSince")}
                        </div>
                        <div id="homeDescriptionYearDiv">
                            1951
                        </div>
                        <div id="homeDescriptionTextDiv">
                            {t("homeDescriptionText1")}<br/>{t("homeDescriptionText2")}<br/>{t("homeDescriptionText3")}
                        </div>
                    </div>
                    <div id="homeDescriptionRightDiv">

                    </div>
                </div>
                <div id="homeAboutUsButtonDiv">
                    <button id="homeAboutUsButton" onClick={()=>{navigate("/aboutUs");}}>{t("homeAboutUsButton")}</button>
                </div>
                <div id="homeStatsDiv">
                    <div className="homeStatDiv">
                        <div className="homeStatTextDiv">
                            <div className="homeStatNumberDiv">
                                72
                            </div>
                            <div className="homeStatDescriptionDiv">
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homeYearsOf')}
                                </div>
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homeExperienceInTheArea')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine" />
                    </div>
                    <div className="homeStatDiv">
                        <div className="homeStatTextDiv">
                            <div className="homeStatNumberDiv">
                                1200
                            </div>
                            <div className="homeStatDescriptionDiv">
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homePaperTons')}
                                </div>
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homeTransformedPerYear')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine" />
                    </div>
                    <div className="homeStatDiv">
                        <div className="homeStatTextDiv">
                            <div className="homeStatNumberDiv">
                                46
                            </div>
                            <div className="homeStatDescriptionDiv">
                                <div className="homeStatDescriptionEmptyLineDiv">

                                </div>
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homeColaborators')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine" />
                    </div>
                    <div className="homeStatDiv">
                        <div className="homeStatTextDiv">
                            <div className="homeStatNumberDiv">
                                929
                            </div>
                            <div className="homeStatDescriptionDiv">
                                <div className="homeStatDescriptionEmptyLineDiv">

                                </div>
                                <div className="homeStatDescriptionLineDiv">
                                    {t('homeClientsAndPartners')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine" />
                    </div>
                </div>
                <div id="homeBrandDescriptionDiv">
                    <div id="homeDLDIs">
                        {t('homeDLDIs')}
                    </div>
                    <div id="homeBrandDescriptionOwnerImage"></div>
                    <div id="homeBrandDescriptionRightDiv">
                        <div id="homeDLDDescriptionElement"><HomeDLDDescription/></div>
                        <div id="homeBrandDescriptionRightDivText">
                            <div id="homeFounderNameDiv">
                                <p id="homeFounderName"><li><strong>Eduardo Coutinho</strong></li></p>
                                <p id="founder-word-home">{t("brandDescriptionFounder")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="homeVideoDiv">
                    <Video width="100%" height="36vw"/>
                </div>
                <div id="homeProductsDiv">
                    <div id="homeProductsInfoDiv">
                        <div id="homeProductsInfoTitleDiv">{t('productsUC')}</div>
                        <div id="homeProductsInfoDescriptionDiv">{t('homeProductsInfoDescription1')}</div>
                        <div id="homeProductsInfoDescriptionDiv2">{t('homeProductsInfoDescription2')}</div>
                        <div id="homeProductsInfoButtonDiv">
                            <button id="homeAboutUsButton" onClick={()=>{navigate("/products");}}>{t('homeAboutUsButton')}</button>
                        </div>
                    </div>
                    <div id="homeProductsButtons">
                        <HomeProductButtons/>
                    </div>
                </div>
            </div>
            {/*Mobile version*/}
            <div className='Container d-lg-none d-block'>
                <div className="homePage-mobile">
                <div className="player-wrapper-mobile">
                    <ReactPlayer url={capa_4} playing={true} loop={true} controls={false} volume="0" width="355vw" height="200vw"/>
                </div>
                    <div id="homeTitleDivOverlay-mobile">
                        <div id="dld-text-mobile">
                            DLD
                        </div>
                        <div id="industria-grafica-mobile">
                            INDÚSTRIA GR�?FICA
                        </div>
                    </div>

                    <div className="homeDescriptionDiv-mobile d-flex">
                        <div id="homeDescriptionLeftDiv-mobile">
                            <div id="homeDescriptionSinceDiv-mobile">
                                {t("homeDescriptionSince")}
                            </div>
                            <div id="homeDescriptionYearDiv-mobile">
                                1951
                            </div>
                            <div id="homeDescriptionTextDiv-mobile">
                                {t("homeDescriptionText1")}<br/>{t("homeDescriptionText2")}<br/>{t("homeDescriptionText3")}
                            </div>
                        </div>
                        <div className="homePaletteDiv">
                            <img src={homePalette} alt="layout" id="homePalette-mobile" />
                        </div>
                    </div>

                    <div id="homeStatsDiv-mobile">
                        <div className="homeStatDiv-mobile">
                            <div className="homeStatTextDiv-mobile d-flex">
                                <div className="homeStatNumberDiv-mobile">
                                    72
                                </div>
                                <div className="homeStatDescriptionDiv-mobile">
                                    <div className="homeStatDescriptionLineDiv-mobile">
                                        {t('homeYearsOf')}
                                    </div>
                                    <div className="homeStatDescriptionLineDiv-mobile">
                                        {t('homeExperienceInTheArea')}
                                    </div>
                                </div>
                            </div>
                            <hr className="homeStatLine-mobile" />
                        </div>

                        <div className="homeStatTextDiv-mobile d-flex">
                            <div className="homeStatNumberDiv-mobile">
                                46
                            </div>
                            <div className="homeStatDescriptionDiv-mobile">
                                <div className="homeStatDescriptionLineDiv-mobile" id="goDown">
                                    {t('homeColaborators')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine-mobile" />
                    
                        <div className="homeStatTextDiv-mobile d-flex">
                            <div className="homeStatNumberDiv-mobile">
                                1200
                            </div>
                            <div className="homeStatDescriptionDiv-mobile">
                                <div className="homeStatDescriptionLineDiv-mobile">
                                    {t('homePaperTons')}
                                </div>
                                <div className="homeStatDescriptionLineDiv-mobile">
                                    {t('homeTransformedPerYear')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine-mobile" />
                    
                        <div className="homeStatTextDiv-mobile d-flex">
                            <div className="homeStatNumberDiv-mobile">
                                929
                            </div>
                            <div className="homeStatDescriptionDiv-mobile">
                                <div className="homeStatDescriptionLineDiv-mobile">
                                    {t('homeClients')}<br/>{t('homeAndPartners')}
                                </div>
                            </div>
                        </div>
                        <hr className="homeStatLine-mobile" />
                    </div>
                    <div id="homeMobileAboutUsButtonDiv">
                        <button id="homeMobileAboutUsButton" onClick={()=>{navigate("/aboutUs")}}>{t("homeAboutUsButton")}</button>
                    </div>
                    <div id="homepageOwnerDivMobile">
                            <div id="dldIsMobile">{t("homeDLDIs")}</div>
                            <div><HomeDLDDescription/></div>
                            <div id="homeFounderNameDivMobile">
                                <p id="homeFounderNameMobile"><li><strong>Eduardo Coutinho</strong></li></p>
                                <p id="founder-word-home-mobile">{t("brandDescriptionFounder")}</p>
                            </div>
                    </div>
                    <div id="homeVideoDivMobile">
                        <Video width="300%" height="36vw"/>
                    </div>
                    <div id="mobileProductsDiv">
                        <div id="mobileProductsTitleDiv">{t("productsUC")}</div>
                        <div id="mobileProductsDescription">{t("mobileProductsDescription1")}</div>
                        <div id="mobileProductsDescription">{t("mobileProductsDescription2")}</div>
                        <img src={bottle} id="homeProductsMobileImage"/>
                    </div>
                    <div id="homeProductsMobileTiles">
                        <div className="homeProductsMobileTilesRow">
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellFashion">
                                <img src={productsFashion} id="homeProductsImageFashion"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("fashionUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/fashion");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellIndustry">
                                <img src={productsIndustry} id="homeProductsImageIndustry"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("industryUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/industry");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                        </div>
                        <div className="homeProductsMobileTilesRow">
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellFood">
                                <img src={productsFood} id="homeProductsImageFood"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("foodIndustryUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/food");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellGraphical">
                                <img src={productsGraphical} id="homeProductsImageGraphical"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("layout-graphical")}</div>    
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/graphical");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Home;