import React from "react";
import "../../Styles/Animations/Circles.css";

function CirclesBlue() {
    return (
        <div className="circles-blue">
            <div className="circle-3" style={{ backgroundColor: 'rgba(173, 199, 210, 0.25)' }}></div>
            <div className="circle-2" style={{ backgroundColor: 'rgba(173, 199, 210, 0.25)' }}></div>
            <div className="circle-1" style={{ backgroundColor: 'rgba(173, 199, 210, 0.5)' }}></div>
        </div >
    )
}

export default CirclesBlue;
