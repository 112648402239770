import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";



function FailedLogin(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="d-none d-lg-block">
                <div id="failedLoginContainer">
                    <Row id="failedTitleRow">
                        <Col id="failedTitleText">
                            {t("loginCredentials")}
                        </Col>
                    </Row>
                    <Row id="failedLoginMessageRow">
                        <Col>
                            <p id="failedLoginText">{t("failedLoginText")}</p>
                        </Col>
                    </Row>
                    <Row id="backButtonRow">
                        <Col ></Col>
                        <Col xs={7}>
                            <Button id="backButton" onClick={() => { navigate("/login");props.setFailureState(true)}}>{t("backButton")}</Button>
                        </Col>
                        <Col ></Col>
                    </Row>
                </div>
            </div>
            {/*Mobile version*/}
            <div className="d-block d-lg-none">
                <div id="Container-failed-mobile">
                    <Row id="failedTitleRow-mobile">
                        <Col id="failedTitleText-mobile">
                            {t("loginCredentials")}
                        </Col>
                    </Row>
                    <Row id="failedLoginMessageRow-mobile">
                        <Col>
                            <p id="failedLoginText-mobile">{t("failedLoginText")}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

FailedLogin.propTypes = {
    setFailureState: PropTypes.func.isRequired
  }

export default FailedLogin;