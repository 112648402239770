import React from "react";
import { useState } from "react";
import "../Styles/LanguageDropDown.css";
import LanguageIcon from "../Icons/LanguageIconDark.svg";
import { useTranslation } from 'react-i18next';


function LanguageDropDown() {

    const { i18n } = useTranslation();

    function handleLanguageButtonClick(lang) {
        i18n.changeLanguage(lang)
    }

    const [state, setState] = useState(false);

    function rotateAndOpen() {
        setState(state => !state);
    }


    return (
        <div>
            <div id="icon" className={state ? 'rotate' : 'normal'} type="button" onClick={rotateAndOpen} >
                <img src={LanguageIcon} alt="LANGUAGE" id="languageIcon" />
            </div>
            <div id="PT" className={"language-options " + (state ? "dropdown " : "hidden ") + (i18n.language === 'pt' ? "active " : "")} onClick={() => handleLanguageButtonClick('pt')}>PT</div>
            <div id="EN" className={"language-options " + (state ? "dropdown " : "hidden ") + (i18n.language === 'en' ? "active " : "")} onClick={() => handleLanguageButtonClick('en')}>EN</div>
            <div id="ES" className={"language-options " + (state ? "dropdown " : "hidden ") + (i18n.language === 'es' ? "active " : "")} onClick={() => handleLanguageButtonClick('es')}>ES</div>
        </div>
    );

}


export default LanguageDropDown;