import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

function FailedLogin() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="d-none d-lg-block">
                <div id="errorLoginContainer">
                    <Row id="errorTitleRow">
                        <Col id="errorTitleText">
                            {t("nonExistentPageTitle")}
                        </Col>
                    </Row>
                    <Row id="errorLoginMessageRow">
                        <Col>
                            <p id="errorLoginText">{t("nonExistentPageText")}</p>
                        </Col>
                    </Row>
                    <Row id="backButtonRow">
                        <Col ></Col>
                        <Col xs={7}>
                            <Button id="backButton" onClick={() => { navigate("/home") }}>{t("backButton")}</Button>
                        </Col>
                        <Col ></Col>
                    </Row>
                </div>
            </div>
            {/*Mobile version*/}
            <div className="d-block d-lg-none">
                <div id="Container-error-mobile">
                    <Row id="errorTitleRow-mobile">
                        <Col id="errorTitleText-mobile">
                            {t("nonExistentPageTitle")}
                        </Col>
                    </Row>
                    <Row id="errorLoginMessageRow-mobile">
                        <Col>
                            <p id="errorLoginText-mobile">{t("nonExistentPageText")}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default FailedLogin;