import '../Styles/AboutUs.css';
import { useTranslation } from 'react-i18next';
import pallete from '../assets/image/aboutUs/palette.png';
import rectangle from '../assets/image/aboutUs/rectangle.svg';
// import textil from '../assets/image/aboutUs/textil.svg';
// import industria from '../assets/image/aboutUs/industria.svg';
// import alimentar from '../assets/image/aboutUs/alimentar.svg';
import oldLogo from '../assets/image/aboutUs/old-logo.svg';
//import ReactPlayer from 'react-player';
import Fundador from "../assets/image/aboutUs/fundador.png";

import Factory1 from '../Components/Animations/Factory1';
import Factory2 from '../Components/Animations/Factory2';
import factory3 from "../assets/image/aboutUs/tempFactory3.png";
import factory4 from "../assets/image/aboutUs/tempFactory4.png";
// import teamPhoto from "../assets/image/aboutUs/tempTeamPhoto.png";
import Video from './Animations/video';
import PalletteAnimation from './Animations/pallette_animation';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import CirclesBlue from './Animations/CirclesBlue';
import CirclesOrange from './Animations/CirclesOrange';
import arrow from "../assets/image/products/arrow.svg";
import productsFashion from "../assets/image/home/products-fashion.png";
import productsIndustry from "../assets/image/home/products-industry.png";
import productsFood from "../assets/image/home/products-food.png";
import productsGraphical from "../assets/image/home/products-graphical.png";

import { useNavigate } from 'react-router-dom';

import logo1 from '../assets/logos/logo(1).png';
import logo2 from '../assets/logos/logo(2).png';
import logo3 from '../assets/logos/logo(3).png';
import logo4 from '../assets/logos/logo(4).png';
import logo5 from '../assets/logos/logo(5).png';
import logo6 from '../assets/logos/logo(6).png';
import logo7 from '../assets/logos/logo(7).png';
import logo8 from '../assets/logos/logo(8).png';
import logo9 from '../assets/logos/logo(9).png';
import logo10 from '../assets/logos/logo(10).png';
import logo11 from '../assets/logos/logo(11).png';
import logo12 from '../assets/logos/logo(12).png';
import logo13 from '../assets/logos/logo(13).png';
import logo14 from '../assets/logos/logo(14).png';
import logo15 from '../assets/logos/logo(15).png';
import logo16 from '../assets/logos/logo(16).png';
import logo17 from '../assets/logos/logo(17).png';
import logo18 from '../assets/logos/logo(18).png';
import logo19 from '../assets/logos/logo(19).png';
import logo20 from '../assets/logos/logo(20).png';
import logo21 from '../assets/logos/logo(21).png';
import logo22 from '../assets/logos/logo(22).png';
import logo23 from '../assets/logos/logo(23).png';
import logo24 from '../assets/logos/logo(24).png';
import logo25 from '../assets/logos/logo(25).png';
import logo26 from '../assets/logos/logo(26).png';
import logo27 from '../assets/logos/logo(27).png';
import logo28 from '../assets/logos/logo(28).png';
import logo29 from '../assets/logos/logo(29).png';
import logo30 from '../assets/logos/logo(30).png';
import logo31 from '../assets/logos/logo(1).jpg';
import logo32 from '../assets/logos/logo(2).jpg';
import logo33 from '../assets/logos/logo(3).jpg';
import logo34 from '../assets/logos/logo(4).jpg';
import logo35 from '../assets/logos/logo(1).webp';
import logo36 from '../assets/logos/logo(2).webp';

import PalletteAnimation1 from '../assets/image/aboutUs/PalletteAnimation1.png';
import PalletteAnimation2 from '../assets/image/aboutUs/PalletteAnimation2.png';

import { useEffect, useState } from 'react';

const AboutUs = () => {

    // const isIphone = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const isMobile = window.matchMedia('(max-width: 991px)').matches;

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        const element = document.querySelector(".pallete-mobile");
        if (element == null){
            return;
        }
        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;
        const screenHeight = window.innerHeight;
  
        if(isMobile){
          // Check if the element is in the viewport
          if(elementTop != null && elementBottom != null){
            if (elementBottom > 0 && elementTop < screenHeight*0.35) {
                setScroll(true);
            } else {
                setScroll(false);
            }
            }
        }
      });
  
  
    }, [scroll, window.scrollY]);


    /* select 15 elements from a 20 element array without repeating any selected elements */
    
    const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20, logo21, logo22, logo23, logo24, logo25, logo26, logo27, logo28, logo29, logo30, logo31, logo32, logo33, logo34, logo35, logo36];
    const selectedLogos = useMemo(() => {
        const logosCopy = [...logos];
        const selected = [];

        const nLogos = isMobile ? 9 : 15;
    
        while(selected.length < nLogos){
            const randomIndex = Math.floor(Math.random() * logosCopy.length);
            
            if(!selected.includes(logosCopy[randomIndex])){
                selected.push(logosCopy[randomIndex]);
            }
            
            logosCopy.splice(randomIndex, 1);
        }

        return selected;
    }, []);

    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className='Container d-none d-lg-block'>
                <div className='aboutUs'>
                    <div className='text col-lg-6 col-md-6' id='aboutus-text'>
                        <p id="title">{t('aboutUsUC')}</p>
                        <p id="description">{t('aboutUs-description')}</p>
                        <p id="description-no-margin-bottom">{t('aboutUs-description2')}</p>
                        <p>{t('aboutUs-description3')}</p>
                        <p id="description">{t('aboutUs-description4')}</p>
                    </div>
                    <div className='video'>
                        <Video width="100%" height="36vw" />
                    </div>
                    <div className='pallete_icons col-lg-6'>
                        <img src={rectangle} alt="rectangle" id="rectangle" />
                        <img src={pallete} alt="pallete" id="icon-pallete" />
                    </div>
                </div>
                <div className='ourPast d-none d-md-flex col-lg-6 '>
                    <div className='d-flex flex-column'>
                        <div className='text' id='ourPast-text'>
                            <p className='teste' id="title">{t('ourPastUC')} </p>
                            <p id="description">{t('ourPast-description1')}<br></br>{t('ourPast-description2')} </p>
                           
                        </div>
                        <div className='ourPast-image'>
                            <img src={oldLogo} alt="old-logo" id="oldlogo-img" />
                        </div>
                    </div>

                    <div className='pallete-image'>
                        <PalletteAnimation />
                    </div>
                </div>

                <div className='facilities d-flex' >
                    <div className='text col-lg-6' id='facilities-text'>
                        <p id="title">{t('facilitiesUC')}</p>
                        <p id="description">{t('facilities-description1')}</p>
                        <p id="description">{t('facilities-description2')}</p>
                        <div className='factory2'><Factory2 /></div>
                    </div>
                    <div className='col-xl-6 col-lg-6' id="factory1">
                        <Factory1 />
                    </div>
                </div>

                <div className='founder d-flex'>
                    <div className='founder-image-div col-lg-5'>
                        <img src={Fundador} alt='founder' id="founder-img" />
                    </div>
                    <div className='plant-image col-lg-7 position-relative'>
                        <div className='text' id='founder-text'>
                            <p> {t('founder-text')}<strong>{t('founder-text-bold')}</strong></p>
                            <p> {t('founder-text-2')}</p>
                            
                            <p id="founder-name"><li><strong>Eduardo Coutinho</strong></li></p>
                            <p id="founder-word">{t('founder')}</p>
                        </div>
                    </div>
                </div>
                {/* products buttons */}
                <div className='products-title'>
                    <p id="title">{t('productsUC')}</p>
                </div>

                <div className='products-box-container'>
                    <div className='products-box-1'>
                        <div className='background-blur-1'>
                            <p className='products-box-text'>{t('fashionUC')}</p>
                        </div>
                        <Link className='products-button-1' to='/fashion'>
                            {t('homeAboutUsButton')}
                        </Link>
                    </div>

                    <div className='products-box-2'>
                        <div className="background-blur-2">
                            <p className='products-box-text'>{t('industryUC')}</p>
                        </div>
                        <Link className='products-button-2' to='/industry'>
                            <p>  {t('homeAboutUsButton')}</p>
                        </Link>
                        
                    </div>

                    <div className='products-box-3'>
                        <div className='background-blur-3'>
                            <p className='products-box-text'>{t('foodIndustryUC')}</p>
                        </div>
                        <Link className='products-button-3' to='/food'>
                            {t('homeAboutUsButton')}
                        </Link>
                    </div>

                    <div className='products-box-4'>
                        <div className='background-blur-4'>
                            <p className='products-box-text'>{t('layout-graphical')}</p>
                        </div>
                        <Link className='products-button-4' to='/graphical'>
                            {t('homeAboutUsButton')}
                        </Link>
                    </div>
                </div>
                <div className='brands'>
                    <div className='text col-lg-12' >
                        <p id="title">{t('clientsUC')}</p>
                        <p id="aboutus-clients-description">{t('clients-description-1')}</p>
                        <p id="aboutus-clients-description">{t('clients-description-2')}</p>
                    </div>
                    <div className='brands-logos-container'>
                        <div className="brands-logos" id="brands-logos">
                            {selectedLogos.map((element, index) => {
                                return (
                                    <div className={`logo${index + 1}`} key={index} style={{display: 'flex', justifyContent: 'center'}}>
                                        <img src={element} width="80%" height="auto" alt="logo" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/*Mobile version*/}
            <div className='Container d-lg-none d-block'>
                <div className='aboutUs-mobile'>
                    <div className='text-mobile col-lg-6' id='aboutus-text-mobile'>
                        <p id="title-mobile-aboutUs">{t('aboutUsUC')}</p>
                        <p id="description-mobile">{t('aboutUs-description')}</p>
                        <p id="description-mobile">{t('aboutUs-description2')}</p>
                        <p id="description-mobile">{t('aboutUs-description3')}</p>
                    </div>
                    <div className='pallete-icons-mobile'>
                        <img src={rectangle} alt="rectangle-mobile" id="rectangle-mobile" />
                        <img src={pallete} alt="pallete" id="icon-pallete-mobile" />
                    </div>
                </div>
                <div className='video-mobile'>
                    <Video width="100%" height="36vw" />
                </div>

                <div className='facilities-mobile d-flex' >
                    <div className='text-mobile facilities-text-div-sm-mobile'>
                        <p id="title-mobile-aboutUs">{t('facilitiesUC')}</p>
                        <p id="description-mobile">{t('facilities-description1')}</p>
                        <p id="description-mobile">{t('facilities-description2')}</p>
                        <div className="circles-orange-mobile">
                            <CirclesOrange />
                            
                            <div className='circles-orange-text'>
                                <span className="area-text-mobile"><strong>34 {t('machines')}</strong>  </span><br></br>
                                <span className="normal-text">{t('constant-operation')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='factory1-image-mobile'>
                        <img id="image" src={factory3} alt="Factory1Image" className="factory1-image-div-mobile" />
                    </div>
                </div>

                <div className='factory-area-div'>
                    <p id="area-text-mobile">+ 8 . 0 0 0 m 2</p>
                    <div className='circles-blue-mobile'>
                        <CirclesBlue/>
                    </div>
                    <img id="image" src={factory4} alt="Factory Side Image" className="factory4-image-mobile" />
                </div>

                <div className='pallete-mobile'>
                    <div className= {scroll ? "iphone-pallette-animate" : "iphone-pallette"}>
                        <img src={PalletteAnimation1} className="center"/>
                        <img src={PalletteAnimation2} className="center"/>
                    </div>                    
                </div>
                <img id="image" src={oldLogo} alt="Team photo" className='team-photo-mobile' />

                <div className='founder-mobile d-flex'>
                    
                    <div className='text-mobile' id='founder-text-mobile-sm'>
                        <h2 id="who-we-are-text-mobile">{t('who-are-we')}</h2>
                        <p id="founder-text-mobile">{t('founder-text')}<strong>{t('founder-text-bold')}</strong></p>
                        <p id="founder-text-mobile">{t('founder-text-2')}</p>
                        <p id="founder-name-mobile"><li><strong>Eduardo Coutinho</strong></li></p>
                        <p id="founder-word-mobile">{t('founder')}</p>
                    </div>
                </div>

                <div className='product d-flex'>
                    <div className='product-text-mobile text-mobile'>
                            <p id='title-mobile-aboutUs'>{t('productUC')}</p>
                            
                    </div>
                </div>

                <div id="homeProductsMobileTiles">
                        <div className="homeProductsMobileTilesRow">
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellFashion">
                                <img src={productsFashion} id="homeProductsImageFashion"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("fashionUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/fashion");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellIndustry">
                                <img src={productsIndustry} id="homeProductsImageIndustry"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("industryUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/industry");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                        </div>
                        <div className="homeProductsMobileTilesRow">
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellFood">
                                <img src={productsFood} id="homeProductsImageFood"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("foodIndustryUC")}</div>
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/food");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                            <div className="homeProductsMobileTilesCell" id="homeProductsMobileTilesCellGraphical">
                                <img src={productsGraphical} id="homeProductsImageGraphical"/>
                                <div className="homeProductsMobileTilesCellTitle">{t("layout-graphical")}</div>    
                                <button className="homeProductsMobileButton" onClick={()=>{navigate("/graphical");}}>
                                    {t("homeAboutUsButton")}
                                    <img src={arrow} className="homeProductsArrowImage"/>
                                </button>
                            </div>
                        </div>
                </div>

                <div className='ourClients d-flex'>
                    <div className='product-text-mobile text-mobile'>
                            <p id='title-mobile-aboutUs'>{t('clientsUC')}</p>
                            
                    <div className='text-mobile d-sm-none' id='founder-text-mobile-xs'>
                        <p id="description-mobile">{t('brands-description1')}<br></br>{t('brands-description2')}<br></br>{t('brands-description3')}</p>
                    </div>
                    </div>
                </div>
                <div className='brands-logos-container-mobile'>
                        <div className="brands-logos-mobile" id="brands-logos">
                            {selectedLogos.map((element, index) => {
                                return (
                                    <div className={`logo${index + 1}`} key={index} style={{display: 'flex', justifyContent: 'center'}}>
                                        <img src={element} width="80%" height="auto" alt="logo" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            </div>

        </div>
    );
}

export default AboutUs;