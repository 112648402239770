import React from "react";
import "../Styles/StartAnimation.css";
import {motion} from 'framer-motion';
import { useNavigate } from "react-router-dom";
import {useMemo} from 'react';

function StartAnimation(){

    //Constants to get current width, and since for some reason it doesnt work, we still multiply by a, which by trial and error should be 23
    const navigate = useNavigate();
    window.startAnimationPrecedence = true;
    const width = window.innerWidth;
    const a = 23

    const animationDuration = 2;

    const isMobile = window.matchMedia('(max-width: 991px)').matches;
    useMemo(() => {
        if(isMobile){
            setTimeout(()=>{
                window.startAnimationPrecedence = true;
                navigate("home")
            },50)
        }else{
            setTimeout(()=>{
                window.startAnimationPrecedence = true;
                navigate("home")
            },animationDuration * 1000)
        }
    },[]);

    return (
      <motion.div id="background" key="startAnimationBackgroundKey" exit={{opacity:0}} animate={{opacity:1}} transition={{duration:0.5}}>
          <motion.div 
              id="circle"
              initial={{scale:0,scaleY:0}}
              animate={{scale:[null,1.4,0.8,1.1,1,1,2.5,5,5,10,20],translateX:[0,0,0,0,0,0,-25 *a* width/1920,-50 *a*width/1920,-50*a*width/1920,-25*a*width/1920,0],scaleY:[null,1,1,1,1,1,0,1,1,0,1]}}
              transition={{times:[0,0.08,0.14,0.18,0.20,0.5,0.58,0.66,0.82,0.91,1],duration:animationDuration}}>
          </motion.div>
          <div id="dld-text">
              DLD
          </div>
          <div id="industria-grafica">
              INDÚSTRIA GR�?FICA
          </div>
          {isMobile &&(
            <div id="mobileStartAnimationOverlay"></div>
          )}
      </motion.div>
    );
}


export default StartAnimation;