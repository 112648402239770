import '../Styles/HomeProductButtons.css'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

function HomeProductButtons(){
    const navigate = useNavigate();
    return(
        <div id="homeProductsButtonsMainDiv">
            <div id="homeFoodButton1Div" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"16vw"}} onClick={()=>{navigate("/food");}}>{t("foodUC")}</div></div>
            <div id="homeIndustryButton1Div" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"7vw"}} onClick={()=>{navigate("/industry");}}>{t("industryUC")}</div></div>
            <div id="homeFashionButton1Div" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"7vw"}} onClick={()=>{navigate("/fashion");}}>{t("fashionUC")}</div></div>
            <div id="homeGraphicalButtonDiv" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"7vw"}} onClick={()=>{navigate("/graphical");}}>{t("layout-graphical")}</div></div>
            <div id="homeIndustryButton2Div" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"7vw"}} onClick={()=>{navigate("/industry");}}>{t("industryUC")}</div></div>
            <div id="homeFashionButton2Div" className='homeProductsButtonsImage'><div className='homeProductsButtonsForeground' style={{paddingTop:"7vw"}} onClick={()=>{navigate("/fashion");}}>{t("fashionUC")}</div></div>
        </div>
    );

}

export default HomeProductButtons;