import React from "react";
import { useTranslation } from 'react-i18next';
import graphicalLayout from "../assets/image/catalog/graphical/graphicalLayout.png";
import graphicalLayoutMobile from "../assets/image/catalog/graphical/graphicalLayout-mobile.png";
import graphical1 from "../assets/image/catalog/graphical/graphical1.png";
import caderno from "../assets/image/catalog/graphical/caderno.png"
import cards from "../assets/image/catalog/graphical/cards.png";
import tags from "../assets/image/catalog/graphical/tags.png";
import flyer from "../assets/image/catalog/graphical/flyer.png";
import calendario from "../assets/image/catalog/graphical/calendario.png";
import flyerMobile from "../assets/image/catalog/graphical/flyer-mobile.png";
import calendarioMobile from "../assets/image/catalog/graphical/calendario-mobile.png";
import downArrow from "../assets/image/catalog/down-arrow.png";

function Catalog4() {
    const { t } = useTranslation();
    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="Container d-none d-lg-block">
                <div className="layout" >
                    <div className="start-graphical">
                        <img src={graphicalLayout} alt="layout" id="grayLayout" />
                    </div>
                    <p id="text-layout">{t('layout-graphical')}</p>
                </div>

                <div className="graphical-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product1-title')}</p>
                        <p id="description-catalog">{t('product1-desc')}</p>
                    </div>
                    <div className= "food-img-div col-lg-6 col-md-6" >
                        <img src={graphical1} alt="first" id="graphical-img1"/>
                    </div>
                </div>

                <div className="graphical-products d-flex" id="section2">
                    <div className= "food-img-div col-lg-6 col-md-6">
                        <img src={caderno} alt="notebook" id="graphical-img2"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product2-title')}</p>
                        <p id="description-catalog">{t('product2-desc')}</p>
                    </div>
                </div>
                
                <div className="graphical-products d-flex" id="graphical3-div">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product3-title')}</p>
                        <p id="description-catalog">{t('product3-desc')}</p>
                    </div>
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={cards} alt="notebook" id="graphical-img3"/>
                    </div>
                </div>

                <div className="graphical-products d-flex">
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={tags} alt="tags" id="graphical-img4"/>
                    </div>
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product4-title')}</p>
                        <p id="description-catalog">{t('product4-desc')}</p>
                    </div>
                </div>

                <div className="graphical-products d-flex">
                    <div className="text-catalog col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product5-title')}</p>
                        <p id="description-catalog">{t('product5-desc')}</p>
                    </div>
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={flyer} alt="flyer" id="graphical-img5"/>
                    </div>
                </div>

                <div className="graphical-products d-flex" id="graphical-bottom-div">
                    <div className= "food-img-div-caixa col-lg-6 col-md-6">
                        <img src={calendario} alt="calendar" id="graphical-img6"/>
                    </div>
                    <div className="text-catalog-img6 col-lg-6 col-md-6">
                        <p id="title-catalog">{t('g-product6-title')}</p>
                        <p id="description-catalog">{t('product6-desc')}</p>
                    </div>
                </div>

            </div>

            {/*Mobile version*/}
            <div className='Container d-lg-none d-block'>
                <div className="layout-mobile-catalog">
                    <div className="start-graphical-mobile">
                        <img src={graphicalLayoutMobile} alt="layout" id="GreyLayout-mobile"/>
                    </div> 
                    <p id="text-layout-mobile">{t('layout-graphical')}</p>
                    <img src={downArrow} className="catalogDownArrow"/>
                </div>

                <div className="graphical-products-mobile d-flex">
                    <img src={graphical1} alt="first" id="graphical1mobile-img"/>
                    <div id="catalog4MobileTitle1" className="catalogMobileTitlesLeft">{t("g-product1-title")}</div>
                    <div id="catalog4MobileText1" className="catalogMobileTextsLeft">{t("product1-desc")}</div>
                </div>

                <div className="graphical-products-mobile d-flex">
                    <div id="catalog4MobileTitle2" className="catalogMobileTitlesRight">{t("g-product2-title")}</div>
                    <div id="catalog4MobileText2" className="catalogMobileTextsRight">{t("product2-desc")}</div>
                    <img src={caderno} alt="first" id="graphical2mobile-img"/>                  
                </div>

                <div className="graphical-products-mobile d-flex">
                    <div id="catalog4MobileTitle3" className="catalogMobileTitlesLeft">{t("g-product3-title")}</div>
                    <div id="catalog4MobileText3" className="catalogMobileTextsLeft">{t("product3-desc")}</div>
                    <img src={cards} alt="first" id="graphical3mobile-img"/>
                </div>

                <div className="graphical-products-mobile d-flex">
                    <div id="catalog4MobileTitle4" className="catalogMobileTitlesRight">{t("g-product4-title")}</div>
                    <div id="catalog4MobileText4" className="catalogMobileTextsRight">{t("product4-desc")}</div>
                    <img src={tags} alt="first" id="graphical4mobile-img"/>
                </div>

                <div className="graphical-products-mobile d-flex">
                    <div id="catalog4MobileTitle5" className="catalogMobileTitlesLeft">{t("g-product5-title")}</div>
                    <div id="catalog4MobileText5" className="catalogMobileTextsLeft">{t("product5-desc")}</div>
                    <img src={flyerMobile} alt="first" id="graphical5mobile-img"/>
                </div>

                <div className="graphical-products-mobile d-flex">
                    <div id="catalog4MobileTitle6" className="catalogMobileTitlesRight">{t("g-product6-title")}</div>
                    <div id="catalog4MobileText6" className="catalogMobileTextsRight">{t("product6-desc")}</div>
                    <img src={calendarioMobile} alt="first" id="graphical6mobile-img"/>
                </div>
            </div>
        </div>
    );
}

export default Catalog4;