import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import usernameIcon from "../assets/icons/usernameIcon.svg"
import passwordIcon from "../assets/icons/passwordIcon.svg"
import { useNavigate } from "react-router-dom";

const variants = {
    expanded: { width: "100%" },
    collapsed: { width: "3vw" },
}



function Login() {
    const { t } = useTranslation();
    const [isUsernameCollapsed, setIsUsernameCollapsed] = useState(false)
    const [usernameValue, setUsernameValue] = useState('');
    const [isPasswordCollapsed, setIsPasswordCollapsed] = useState(false)
    const [passwordValue, setPasswordValue] = useState('');

    const navigate = useNavigate();

    const checkUsernameInputEmpty = () => {
        if (usernameValue.trim().length == 0) {
            return true;
        } else {
            return false;
        }
    }

    const handleUsernameInputChange = event => {
        setUsernameValue(event.target.value);
    };

    const checkPasswordInputEmpty = () => {
        if (passwordValue.trim().length == 0) {
            return true;
        } else {
            return false;
        }
    }

    const handlePasswordInputChange = event => {
        setPasswordValue(event.target.value);
    };

    return (
        <div className="Container hide-overflow">
            {/*Desktop version*/}
            <div className="d-none d-lg-block" id="loginDesktopDiv">
                <div id="loginContainer">
                    <Row id="loginTitleRow">
                        <Col id="loginTitleText">
                            {t("loginCredentials")}
                        </Col>
                    </Row>
                    <Row id="loginUsernameRow">
                        <Col>
                            <div id="usernameInputBackgroundDiv"></div>
                            <motion.div
                                id="usernameInputForegroundDiv"
                                animate={isUsernameCollapsed ? "collapsed" : "expanded"}
                                variants={variants}
                                transition={{ ease: "easeOut" }}
                            >
                                <img src={usernameIcon} alt="Username Icon" id="usernameIcon"></img>
                            </motion.div>
                            <input id="usernameInput" className={isUsernameCollapsed ? "loginBlackText" : "loginWhiteText"} onClick={() => setIsUsernameCollapsed(true)} onBlur={() => setIsUsernameCollapsed(() => !checkUsernameInputEmpty())} onChange={handleUsernameInputChange} placeholder={t('loginUsername')} />
                        </Col>
                    </Row>
                    <Row id="loginPasswordRow">
                        <Col>
                            <div id="usernameInputBackgroundDiv"></div>
                            <motion.div
                                id="usernameInputForegroundDiv"
                                animate={isPasswordCollapsed ? "collapsed" : "expanded"}
                                variants={variants}
                                transition={{ ease: "easeOut" }}
                            >
                                <img src={passwordIcon} alt="Password Icon" id="passwordIcon"></img>
                            </motion.div>
                            <input id="usernameInput" className={isPasswordCollapsed ? "loginBlackText" : "loginWhiteText"} type="password" onClick={() => setIsPasswordCollapsed(true)} onBlur={() => setIsPasswordCollapsed(() => !checkPasswordInputEmpty())} onChange={handlePasswordInputChange} placeholder={t('loginPassword')} />
                        </Col>
                    </Row>
                    <Row id="loginButtonRow">
                        <Col xs={3}></Col>
                        <Col xs={6}>
                            <Button id="loginButton" onClick={() => { navigate("/failedLogin") }}>{t("loginButton")}</Button>
                        </Col>
                        <Col xs={3}></Col>
                    </Row>
                </div>
            </div>
            {/*Tablet version*/}
            <div className="d-none d-sm-block d-lg-none">
                <div id="Container-tablet">
                    <Row id="titleRow-tablet">
                        <Col id="titleText-tablet">
                            {t("loginCredentials")}
                        </Col>
                    </Row>
                    <Row id="usernameRow-tablet">
                        <Col>
                            <div id="usernameInputBackgroundDiv-tablet"></div>
                            <div id="usernameInputForegroundDiv-tablet">
                                <img src={usernameIcon} alt="Username Icon" id="usernameIcon-tablet"></img>
                            </div>
                            <input id="usernameInput-tablet" placeholder={t('loginUsername')} />
                        </Col>
                    </Row>
                    <Row id="passwordRow-tablet">
                        <Col>
                            <div id="usernameInputBackgroundDiv-tablet"></div>
                            <div id="usernameInputForegroundDiv-tablet">
                                <img src={passwordIcon} alt="Password Icon" id="passwordIcon-tablet"></img>
                            </div>
                            <input type="password" id="usernameInput-tablet" placeholder={t('loginPassword')} />
                        </Col>
                    </Row>
                    <Row id="buttonRow-tablet">
                        <Col xs={3}></Col>
                        <Col xs={6}>
                            <Button id="loginButton-tablet" onClick={() => { navigate("/failedLogin") }}>{t("loginButton")}</Button>
                        </Col>
                        <Col xs={3}></Col>
                    </Row>
                </div>
            </div>
            {/*Mobile version*/}
            <div className="d-block d-sm-none">
                <div id="Container-mobile">
                    <Row id="titleRow-mobile">
                        <Col id="titleText-mobile">
                            {t("loginCredentials")}
                        </Col>
                    </Row>
                    <Row id="usernameRow-mobile">
                        <Col>
                            <div id="usernameInputBackgroundDiv-mobile"></div>
                            <div id="usernameInputForegroundDiv-mobile">
                                <img src={usernameIcon} alt="Username Icon" id="usernameIcon-mobile"></img>
                            </div>
                            <input id="usernameInput-mobile" placeholder={t('loginUsername')} />
                        </Col>
                    </Row>
                    <Row id="passwordRow-mobile">
                        <Col>
                            <div id="usernameInputBackgroundDiv-mobile"></div>
                            <div id="usernameInputForegroundDiv-mobile">
                                <img src={passwordIcon} alt="Password Icon" id="passwordIcon-mobile"></img>
                            </div>
                            <input type="password" id="usernameInput-mobile" placeholder={t('loginPassword')} />
                        </Col>
                    </Row>
                    <Row id="buttonRow-mobile">
                        <Col xs={3}></Col>
                        <Col xs={6}>
                            <Button id="loginButton-mobile" onClick={() => { navigate("/failedLogin") }}>{t("loginButton")}</Button>
                        </Col>
                        <Col xs={3}></Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Login;