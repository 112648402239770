import React from "react";
import "../../Styles/Animations/Factory1.css";
import Factory1Image from "../../assets/image/aboutUs/tempFactory1.png";
import CirclesBlue from "../../Components/Animations/CirclesBlue";

function Factory1() {
    return (
        <div className="Factory1">
            <img id="image-factory1" src={Factory1Image} alt="Factory1Image" className="image" />
            <p id="factory2-text" className="factory2-text">8.000m²</p>
            <div className="circlesBlue">
                <CirclesBlue />
            </div>
        </div>
    )
}

export default Factory1;