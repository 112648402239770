import React from "react";
import "../../Styles/Animations/Factory2.css";
import Factory2Image from "../../assets/image/aboutUs/Render_final 1 1.png";
import CirclesOrange from "../../Components/Animations/CirclesOrange";
import { useTranslation } from 'react-i18next';

function Factory2() {
    const { t } = useTranslation();

    return (
        <div className="Factory2">
            <img id="image-factory2" src={Factory2Image} alt="Factory2Image" className="image" />
            <div className="circlesOrange">
                <CirclesOrange />
            </div>
            <p id="factory2-text" className="factory2-text">
                <span className="bold-text">34 {t('machines')}</span><br></br>
                <span className="normal-text">{t('constant-operation')}</span>
            </p>
           
        </div>
    )
}

export default Factory2;