import '../Styles/Catalog3.css';
import Footer from '../Components/Footer';
import Catalog3 from '../Components/Catalog3';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const Catalog3Page = () => {
  {/*This is used to deactivate the web animation between pages*/ }
  const isMobile = window.matchMedia('(max-width: 991px)').matches;
  //Code to make page scroll up like a power point 
  const [pageStarting, setPageStarting] = useState(true);
  useEffect(()=>{
    setTimeout(()=>{if(pageStarting){setPageStarting(false);window.scrollTo({top:0,left:0,behavior:"auto"});console.log("SUCCESS")}},550);
  })

  return (
    <motion.div id="catalog3"
      initial={!isMobile && ({ top: `100vh` })}
      animate={!isMobile && ({ top: "0vh" })}
      exit={!isMobile && ({ filter: 'brightness(50%)', top: `-20vh` })}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className={(pageStarting?"page-transition-fixed":"page-transition-absolute") + " Container"}
    >
      <Catalog3 />
      <Footer />
    </motion.div>
  );
}

export default Catalog3Page;